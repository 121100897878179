import React from "react"
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import classes from "./Download.module.css"
import { ShakeOutlined } from '@ant-design/icons'
import { HashLink } from 'react-router-hash-link'


function Download() {

  const { t } = useTranslation()

  const year = new Date();

  return (
    <div className={classes.footerContainerBg}>
      <div className={classes.footerContainer}>
        
      
      <Row justify="center">
        <Col xs={{span:22}}  sm={{span:23}} md={{span:23}} lg={{span:23}}>
          <Row justify="center">
            <Col xs={24} sm={24} md={8} lg={8} className={classes.links} >
              <HashLink to="/useful#TouristMaps"><div>{t('Τουριστικοί Χάρτες')}</div></HashLink>
              <HashLink to="/useful#UsefulInfo"><div>{t('Χρήσιμες Πληροφορίες')}</div></HashLink>
              <HashLink to="/useful#LiveCams"><div>{t('Live Κάμερες')}</div></HashLink>
              <Link to='/sitemap'><div><span>{t('Sitemap')}</span></div></Link>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} className={classes.links}>
              <HashLink to='/useful#PrintMaps'><div>{t('Χάρτες Διαδρομών')}</div></HashLink>
              <Link to='/routes'><div>{t('Διαδρομές')}</div></Link>
              <Link to='/events'><div>{t('Εκδηλώσεις')}</div></Link>
              <Link to='/municipal_social_networks'><div>{t('Κοινωνικά Δίκτυα')}</div></Link>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} className={classes.links}>
              <Link to='page/about_us' ><div>{t('Σχετικά με το Έργο')}</div></Link>
              <Link to='page/accessibility_statement' ><div><span>{t('Δήλωση Προσβασιμότητας')}</span></div></Link>
              <Link to='page/terms' ><div><span>{t('Όροι και προϋποθέσεις')}</span></div></Link>
              <Link to='page/policy' preventScrollReset={true} ><div><span>{t('Πολιτική Απορρήτου')}</span></div></Link>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={24}>
          <div className={classes.hr}><hr/></div>
        </Col>
      </Row>

      <Row justify="center">
        <Col xs={{span:22}}  sm={{span:22}} md={{span:24}} lg={{span:24}}>
          <Row justify="center">
            <Col xs={24} sm={24} md={12} lg={12} className={classes.year}>
              <div>© {year.getFullYear()} Chania Routes All rights reserved.</div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} className={classes.form}>
              <div><Link to='commentForm' className={classes.whiteColor}>{t('Φόρμα Σχολιασμού Περιεχομένου')} </Link></div>
            </Col>
          </Row>
        </Col>
      </Row>
      </div>
    </div>
  )
}

export default Download