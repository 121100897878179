import React,{useState, useEffect, useMemo} from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Row, Col, Button, Radio, Divider, Anchor } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import HelmetComponent from '../../components/Helmet'
import TitleAndSubtitleAndBtn from '../../components/PageComponents/TitleAndSubtitleAndBtn'
import Events from '../Home/components/Events'
import i18n from '../../../src/translation'

const Useful: React.FC = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { hash } = useLocation()
    // navigate(location.hash)
    const navigateHome = () => {
        navigate('/');
    };

    const [sticky, setSticky] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
          if (window.scrollY >= 5000) {
            setSticky(false)
          } else {
            setSticky(true)
          }
        });
      }, []);


    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
           window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 766;

    return (
        <>
            <HelmetComponent title="Χρήσιμα" descrition="Χρήσιμα page" />

            <TitleAndSubtitleAndBtn title={t('Χρήσιμα')} subTitle={''} />

            <div className={ sticky ? classes.sticky : classes.sticky2}>
                <div className={classes.RadioGroupContainer}>
                    <Row justify="center">
                        <Radio.Group>

                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 270 : 220}
                                    items={[
                                        {
                                            key: '1',
                                            href: '#PrintMaps',
                                            title: t('Εκτυπώσιμοι χάρτες - έντυπα (PDF)'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 270 : 220}
                                    items={[
                                        {
                                            key: '1',
                                            href: '#TouristMaps',
                                            title: t('Τουριστικοί Χάρτες'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 250 : 220}
                                    items={[
                                        {
                                            key: '2',
                                            href: '#UsefulInfo',
                                            title: t('Χρήσιμες Πληροφορίες'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 250 : 220}
                                    items={[
                                        {
                                            key: '3',
                                            href: '#WeatherForecast',
                                            title: t('Πρόγνωση Καιρού'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                        </Radio.Group>
                    </Row>
                    <hr className={ classes.hrLine} />
                    <Row justify="center" style={{backgroundColor: "#fff" , borderBottomLeftRadius: '8px',  borderBottomRightRadius: '8px'}}>
                        <Radio.Group>
                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 250 : 220}
                                    items={[
                                        {
                                            key: '4',
                                            href: '#LiveCams',
                                            title: t('Live Κάμερες'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 260 : 220}
                                    items={[
                                        {
                                            key: '5',
                                            href: '#TravelAndHealth',
                                            title: t('Ταξίδι και Υγεία'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                            <Radio.Button className={classes.RadioButtonMenu}>
                                <Anchor
                                    className={classes.anchor}
                                    affix={false}
                                    offsetTop={isMobile ? 260 : 220}
                                    items={[
                                        {
                                            key: '6',
                                            href: '#ProjectPublicityMaterial',
                                            title: t('Υλικό προβολής'),
                                        }
                                    ]}
                                />
                            </Radio.Button>
                        </Radio.Group>
                    </Row>
                </div>
            </div>

            
            <div id="PrintMaps" className={classes.Scroll}>
                <Row justify="center" style={{zIndex:'1'}} >
                    <div className={classes.UsefulSection}>
                        <div style={{ marginBottom: 6 }} className={classes.pointTitle}>{t('Εκτυπώσιμοι χάρτες - έντυπα (PDF)')}</div>
                        <div className={classes.subTitleSection}> {t('Δείτε τους χάρτες και έντυπα των θεματικών διαδρομών της πόλης των χανίων.')}</div>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Διαδρομή H (History) : Μνημεία της Ιστορίας | GR,EN')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/maps/History.pdf' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Διαδρομή F (Fortification) : Οχυρώσεις | GR,EN')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/maps/Fortification.pdf' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>

                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Διαδρομή C (Culture) : Χώροι πολιτισμού & Εκδηλώσεων | GR,EN')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/maps/Culture.pdf' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Διαδρομή Α (Architecture) : Ετκός των τειχών | GR,EN')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/maps/Architecture.pdf' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>


                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Διαδρομή S (Seafront) : Ακτογραμμή - Φύση | GR,EN')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/maps/Seafront.pdf' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Όλες οι θεματικές διαδρομές (H,F,C,A,S) | GR, EN')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/maps/ALL_H_F_C_A_S.pdf' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Οδηγός σημείων ενδιαφέροντος θεματικών διαδρομών (GR)')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/maps/GuideRoutes_GR.pdf' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Οδηγός σημείων ενδιαφέροντος θεματικών διαδρομών (EN)')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/maps/GuideRoutes_EN.pdf' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Row>
            </div>




            <div id="TouristMaps" className={classes.Scroll}>
                <Row justify="center" style={{zIndex:'1'}} >
                    <div className={classes.UsefulSection}>
                        <div style={{ marginBottom: 6 }} className={classes.pointTitle}>{t('Τουριστικοί Χάρτες')}</div>
                        <div className={classes.subTitleSection}> {t('Δείτε τους τουριστικούς χάρτες του Δήμου Χανίων')}:</div>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Χάρτης Νομού Χανίων')} (GR, EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/01.Chania_area_map_50_x_70-02_LowRes_GR_EN.jpg' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Χάρτης Πόλης Χανίων')} (EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/02_Chania_city_map_35_x_50-01_LowRes_EN.jpg' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>

                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Χάρτης Αγίας Μαρίνας')} (EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/04_Agia_Marina_Stalos_map_35_x_ 50-02_low_res_EN.jpg' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>

                        <Divider />

                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Χάρτης Ακρωτηρίου')} (EN)
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/03_Akrotiri_map_35_x_50-01_LowRes_EN.jpg' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}</Button>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Row>
            </div>

            
            <div id="TravelAndHealth"  className={classes.Scroll}>
                <Row justify="center">
                    <div className={classes.UsefulSection}>
                        <div style={{ marginBottom: 6 }} className={classes.pointTitle}>{t('Ταξίδι και Υγεία')}</div>
                        {/* <div className={classes.subTitleSection}> {t('Ταξιδιωτικές πληροφορίες για τον COVID-19')}:</div> */}
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Ταξιδιωτικές πληροφορίες (Υπουργείο Τουρισμού)')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://mintour.gov.gr/covid-19/'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>  



            <div id="UsefulInfo" className={classes.Scroll}>
                <Row justify="center">
                    <div className={classes.UsefulSection}>
                        <div style={{ marginBottom: 6 }} className={classes.pointTitle}>{t('Χρήσιμες Πληροφορίες')}</div>
                        <div className={classes.subTitleSection}> {t('Συλλογή τηλεφώνων και διευθύνσεων για Νοσοκομεία, Τουριστικές και Ταχυδρομικές Υπηρεσίες, Ταξί, Λιμεναρχεία, Λιμάνια και Προξενεία')}:</div>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Χρήσιμα τηλέφωνα - Δήμος Χανίων')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button  
                                        href = {i18n.language == 'gr' ? 'https://www.chania.gr/ta-chania-mou/xrhsthlefwna/useful-info.html' : 'https://www.chaniatourism.gr/useful-info/' }
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>

            <div id="WeatherForecast" className={classes.Scroll}>
                <Row justify="center">
                    <div className={[classes.UsefulSection,classes.Scroll].join(' ')}>
                        <div style={{ marginBottom: 6 }} className={classes.pointTitle}>{t('Πρόγνωση Καιρού')}</div>
                        <div className={classes.subTitleSection}> {t('Η πρόγνωση του καιρού από την Επίσημη ιστοσελίδα της Εθνικής Μετερεωλογικής Υπηρεσίας (Ε.Μ.Υ)')}:</div>
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Ο καιρός στα Χανιά')}
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://www.chania.gr/ta-chania-mou/kairos/weather.html'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>

            <div id="LiveCams"  className={classes.Scroll}>
                <Row justify="center">
                    <div id="LiveCams" className={classes.UsefulSection}>
                        <div style={{ marginBottom: 6 }} className={classes.pointTitle}>{t('Live Κάμερες')}</div>
                        <div className={classes.subTitleSection}> {t('Ζωντανή μετάδοση από διάφορα σημεία του Νομού')}:</div>
                        
                        <Divider />
                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}> {t('Live Κάμερες από το chania.gr')} </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://www.chania.gr/ta-chania-mou/taXaniaLive/deite-live.html'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </div>    

           

            <div id="ProjectPublicityMaterial"  className={classes.Scroll}>
                <Row justify="center">
                    <div className={classes.UsefulSection}>
                        <div style={{ marginBottom: 6 }} className={classes.pointTitle}>{t('Υλικό προβολής')}</div>
                        {/* <div className={classes.subTitleSection}> {t('Ταξιδιωτικές πληροφορίες για τον COVID-19')}:</div> */}
                        <Divider />

                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Βίντεο προβολής του chaniaroutes')} | GR
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://youtu.be/d0UcsB6kLcE'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>

                            </Col>
                        </Row>
                        <Divider /> 
                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Βίντεο προβολής του chaniaroutes')}  | EN
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button
                                        href='https://youtu.be/Ylr6qZbsedM'
                                        target='_blank'
                                        size='large'
                                        className={classes.BackBtn}>
                                        {t('Μετάβαση')}&nbsp;&nbsp;<ArrowRightOutlined />
                                    </Button>
                                </div>

                            </Col>
                        </Row>

                        <Divider /> 
          
                        <Row>
                            <Col xs={24} sm={24} md={19} lg={19}>
                                <div className={classes.sectionTitle}>
                                    {t('Αυτοκόλλητο chaniaroutes Α6')} | GR, EN
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} className={classes.centerVertically}>
                                <div className={classes.sectionTitle}>
                                    <Button href='/img/useful/ChaniaRoutes-Aftokollita.jpg' target='_blank' download size='large'
                                        className={classes.BackBtn}><img src="/img/useful/Download.png" />&nbsp;&nbsp;
                                        {t('Λήψη')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>


                    </div>
                </Row>
            </div> 

            <br/><br/>

            <div style={{ marginBottom: 60 }}>
                <Row justify="center">
                    <Col span={11} xs={24} sm={24} md={11} lg={11}>
                        <div className={classes.sectionTitle}>
                            <Button
                                size='large'
                                icon={<ArrowLeftOutlined />}
                                onClick={() => {
                                    if (!window.location.hash)
                                        navigate(-1)
                                    else
                                        navigate(-2)
                                }
                                }
                                className={classes.ReturnBtn}>&nbsp;&nbsp;{t('Επιστροφή')}
                            </Button>

                        </div>
                    </Col>
                    <div className={classes.space}></div>
                    <Col span={11} xs={24} sm={24} md={11} lg={11}>
                        <div className={classes.sectionTitle}>
                            <Button
                                size='large'
                                className={classes.HomeBtn}
                                onClick={navigateHome}>
                                {t('Αρχική Σελίδα')}&nbsp;&nbsp;<ArrowRightOutlined />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>

            <div>
                <Row justify="center" className={classes.DestinationContainer}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} >
                    <Events color='grey'/>
                </Col>
                </Row>
            </div>
        </>
    )
}

export default Useful
