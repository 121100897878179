
import ky from 'ky'
import {API, APIRoutes, Header, HeaderRoutes} from './configuration'
import i18n from '../../src/translation'


export const axiosRoutes = {

  async getRoute(id: string) {
    const data: any = await ky.get(`${APIRoutes}/route?id=${id}&fetch_files=1&lang=${i18n.language == 'gr' ? 'el' : i18n.language }`, {headers: HeaderRoutes}).json()
    return data.success
  },

  async getRoutes() {
    const data:any = await ky.get(`${APIRoutes}/routes?lang=${i18n.language == 'gr' ? 'el' : i18n.language }`, {headers: HeaderRoutes}).json()
    return data.success
  }

}