import React, { useState, useEffect } from 'react'
import { CalendarOutlined, NodeIndexOutlined, SearchOutlined, MenuOutlined, CaretDownOutlined, DownOutlined, CloseOutlined } from "@ant-design/icons"
import { Button } from 'antd';
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";
import classes from "./Navbar.module.css";
import { UsefulSvg } from './svg'
import { ArrowSvg } from './arrow'
import { HashLink } from 'react-router-hash-link'

import MapSvg from '../../img/svg/mapForMenu'

const Navbar = () => {

  const [toggleMenu, setToggleMenu] = useState(false)
  const navigate = useNavigate()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }


  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', changeWidth)
    return () => {
      window.removeEventListener('resize', changeWidth)
    }
  }, [])

    return (
      <div>
        {(toggleMenu || screenWidth > 900) && (
          <ul className={ [classes.list, classes.items].join(" ")}>
            
            <Button
              onClick={() => navigate('/routes', { replace: false })}
              icon={<NodeIndexOutlined />}
              type="text"
              className={pathname.split('/').some(url => ['routes', 'route', 'exploration'].includes(url)) ? [classes.MenuButton, classes.MenuButtonClicked].join(' ') : classes.MenuButton}
            >
              <span className={classes.first}>{t('Διαδρομές')}</span>
            </Button>
            

            <Button
              onClick={() => navigate('/useful#PrintMaps', { replace: false })}
              icon={<MapSvg width={21} height={21} fill="#63584F" />}
              type="text"
              className={pathname.split('/').some(url => ['bla'].includes(url)) ? [classes.MenuButton, classes.MenuButtonClicked].join(' ') : classes.MenuButton}
            >
              <span className={classes.second}>&nbsp; {t('Χάρτες Διαδρομών')}</span>
            </Button>


            <div className={classes.dropdown} >
              <Button
                onClick={() => navigate('/useful', { replace: false })}
                icon={<UsefulSvg />}
                type="text"
                className={pathname.split('/').some(url => ['useful'].includes(url)) ? [classes.MenuButton, classes.MenuButtonClicked].join(' ') : classes.MenuButton}
              >
                <span className={classes.first}>{t('Χρήσιμα')}&nbsp;<ArrowSvg /></span>
              </Button>
                <div className={classes.dropdownContent}>
                  <span className={classes.useful}><HashLink smooth to="/useful#PrintMaps">{t('Εκτυπώσιμοι χάρτες - έντυπα (PDF)')}</HashLink></span>
                  <span className={classes.useful}><HashLink smooth to="/useful#TouristMaps">{t('Τουριστικοί Χάρτες')}</HashLink></span>
                  <span className={classes.useful}><HashLink smooth to="/useful#UsefulInfo">{t('Χρήσιμες Πληροφορίες')}</HashLink></span>
                  <span className={classes.useful}><HashLink smooth to="/useful#WeatherForecast">{t('Πρόγνωση Καιρού')}</HashLink></span> 
                  <span className={classes.useful}><HashLink smooth to="/useful#LiveCams">{t('Live Κάμερες')}</HashLink></span> 
                  <span className={classes.useful}><HashLink smooth to="/useful#TravelAndHealth">{t('Ταξίδι και Υγεία')}</HashLink></span>
                  <span className={classes.useful}><HashLink smooth to="/useful#ProjectPublicityMaterial">{t('Υλικό προβολής')}</HashLink></span>
                </div>
            </div>

          
            <Button
              onClick={() => navigate('/events', { replace: false })}
              icon={<CalendarOutlined />}
              type="text"
              className={pathname.split('/').some(url => ['events', 'event'].includes(url)) ? [classes.MenuButton, classes.MenuButtonClicked].join(' ') : classes.MenuButton}
            >
              <span className={classes.first}>{t('Εκδηλώσεις')}</span> 
            </Button>




            
{/* 
            {
              screenWidth < 700 ?
                <HashLink
                  to="/useful#PrintMaps"
                  onClick={toggleNav}
                  className={ pathname.split('/').some(url => ['explorations','exploration'].includes(url)) ? classes.bottomLine : ''}
                  state={{ page: 'exploration' }}>
                  <MapSvg width={25} height={25} fill="#63584F" /> <span className={pathname.split('/').some(url => ['explorations','exploration'].includes(url)) ? classes.compass : classes.compassTop}>{t('Χάρτες Διαδρομών').length <= 12 ? t('Χάρτες Διαδρομών') : t('Χάρτες Διαδρομών').slice(0, 12) + ' ...'} </span>
                </HashLink>
              :
                <HashLink
                  to="/useful#PrintMaps"
                  onClick={toggleNav}
                  className={ pathname.split('/').some(url => ['explorations','exploration'].includes(url)) ? classes.bottomLine : ''}
                  state={{ page: 'exploration' }}>
                  <MapSvg width={25} height={25} fill="#63584F" /> <span className={pathname.split('/').some(url => ['explorations','exploration'].includes(url)) ? classes.compass : classes.compassTop}>{t('Χάρτες Διαδρομών')}</span>
                </HashLink>
            } */}
 



            <span className={classes.searchButton} ><Link to="/search" style={{ paddingRight: '0px', paddingLeft: '0px' }}><Button size="large" icon={<SearchOutlined style={{color: '#63584F'}} />} style={{ color: '#63584F', width: '180px' }}><span></span>{ t('Αναζήτηση')}</Button></Link></span>
         
          </ul>
        )}
        <button onClick={toggleNav} className={classes.btn}>{!toggleMenu ? <MenuOutlined /> : <CloseOutlined />}</button>

      </div>
    )

}
export default Navbar
