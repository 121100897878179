
import React, {useState, useEffect, Fragment} from "react"
import { Col, Row, Pagination} from 'antd'
import { useTranslation } from "react-i18next"
import classes from "./styles.module.css"
import { useUserFavoriteAddOrRemovePoint } from '../../../hooks/useUser'
import { useAuth } from "../../../utils/auth"

import PoisComponent from './PoisComponent'

// Redux
import { useDispatch } from 'react-redux'
import { ExplorationPageNumberRedux } from '../../../redux/mapPage/category'

interface Props{
  lg: number
  xs: number
  paginationNumber: number
  paginationSize?: number
  array: any
  total: number
  setPoisesMarkerExploration: any
  isAuth?: boolean
  isAddOrDelete?: boolean
  onClickAddRemove: (idPois: string) => void,
  imageSize?: string
}

const ViewPointsComponent: React.FC<Props> = ({ lg, xs, paginationNumber, paginationSize, array, total, setPoisesMarkerExploration, isAuth, onClickAddRemove, isAddOrDelete, imageSize }:Props ) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const auth = useAuth()
  const [Poises, setPoises] = useState<any[]>(array);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(9);

  console.log(array)

  const [pointId, setPointId] = useState<string>('')
  const [addDel, setAddDel] = useState<number>(0)

  const { refetch } = useUserFavoriteAddOrRemovePoint(auth.userData.id, pointId, addDel)

  useEffect(() => {

    setPoises(array)
    setPoisesMarkerExploration(currentPage * pageSize > array.length ? array.slice((currentPage - 1) * pageSize) : array.slice((currentPage - 1) * pageSize, currentPage * pageSize))

  }, [array])
  
  
  useEffect(() => {

    if (paginationNumber !== 0)
      
    setPoises(paginationNumber * pageSize > array.length ? array.slice((paginationNumber - 1) * pageSize) : array.slice((paginationNumber - 1) * pageSize, paginationNumber * pageSize))
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    setPoisesMarkerExploration(paginationNumber * pageSize > array.length ? array.slice((paginationNumber - 1) * pageSize) : array.slice((paginationNumber - 1) * pageSize, paginationNumber * pageSize)) 
  
  }, [paginationNumber])


  const onChange = (page: number, pageSize: number) => {
    
    dispatch(ExplorationPageNumberRedux(page))
    setCurrentPage(page)
    setPageSize(pageSize)
    setPoises(page * pageSize > array.length ? array.slice((page - 1) * pageSize) : array.slice((page - 1) * pageSize, page * pageSize))
    window.scrollTo({ top: 300, left: 0, behavior: 'smooth' })
    setPoisesMarkerExploration(page * pageSize > array.length ? array.slice((page - 1) * pageSize) : array.slice((page - 1) * pageSize, page * pageSize))
  }


  const onClickAddOrRemove = (text: string, idPois: string, numberAddDel: number) => {

    setPointId(idPois)
    setAddDel(numberAddDel)
  }

  useEffect(() => {
    if (pointId !== '')
        refetch()
  }, [pointId])


  return (
    <>
      <Row justify="center">
        <Col xs={{span: xs}} sm={{span: xs}} md={{span: lg}} lg={{span: lg}}>

          {Poises.slice(0, pageSize).map((pois: any, index: any) => {

          let textShort = `${pois.text.substring(0, imageSize == 'small' ? 100 : 120)}`
          textShort = textShort.slice(0, textShort.lastIndexOf("&"))
            
            return (
              <Fragment key={pois.id}>
                <PoisComponent 
                  id={pois.id}
                  image = {pois.image !== '' ? pois.image : '../img/defaultPoisImage.jpg'}
                  title={pois.title}
                  rate={pois.rate}
                  text={textShort}
                  municipality={pois.municipality}
                  category={pois.category}
                  isAuth={isAuth}
                  isAddOrDelete={isAddOrDelete}
                  onClickAddOrRemovePois={(text, idPois, AddOrDelete) => onClickAddOrRemove(text, idPois, AddOrDelete)}
                  onClickAddRemovePois={(idPois) => onClickAddRemove(idPois)}
                  imageSize={imageSize}
                  codesPerRoute={pois.typesOfRoute}

                />
              </Fragment>
           )
          })}

        </Col>
      </Row>
      
      <Row justify="center">
        <Col span={20}>
          <Pagination
            onChange={onChange}
            className={classes.pagination}
            total={total}
            showSizeChanger ={true}
            showTotal={(total, range) => t('Αποτελέσματα') +' '+ `${range[0]} - ${range[1]}`+ ' ' + t('από') +' '+ `${total}`}
            defaultPageSize={9}
            defaultCurrent={1}
            pageSizeOptions={[9, 21, 60]}
            current={paginationNumber !== 0 ? paginationNumber : 1  }
            // hideOnSinglePage={true}
            // responsive={true}
          />
        </Col>
      </Row>
    </>
  )
}

export default ViewPointsComponent