import { createSlice } from '@reduxjs/toolkit'

interface RoutesArray{ value: string}
interface RoutesState { Routes: RoutesArray[] }

const initialState: RoutesState = {
  Routes: []
};

export const RoutesArrSlice = createSlice({
  name: 'RoutesArr',
  initialState,
  reducers: {

    SetRoutesArray: (state, action) => {
      state.Routes = []
      state.Routes.push(action.payload)

    },

   
  },
})

export const { SetRoutesArray } = RoutesArrSlice.actions
export default RoutesArrSlice.reducer 